<template>
    <b-card :id="formId">
        <div slot="header" v-if="!noHeader">
            <slot name="header">
                <div class="wit-tabbed-form__header">
                    <div class="wit-tabbed-form__header-left">
                        <slot name="header-left">
                            <img v-if="headerLogo" width="35" height="35" :src="getHeaderLogoImg()" />
                            <strong style="margin-left: 5px">{{ headerText }}</strong>
                        </slot>
                    </div>
                    <div class="wit-tabbed-form__header-right" :anyStepInvalid="anyStepInvalid">
                        <slot name="header-right">
                            <slot name="header-right-btn--before"></slot>
                            <transition name="opacity">
                                <b-btn
                                    v-if="details && fetched && dirty && !disabled"
                                    variant="warning"
                                    @click="updateClick"
                                    :disabled="anyStepInvalid || disabled"
                                    id="wit-tabbed-form__header-update"
                                >
                                    <i class="icon-check"></i>
                                    <span> {{ updateLabel }}</span>
                                </b-btn>
                            </transition>
                            <b-btn
                                v-if="showQuickHelp"
                                variant="warning"
                                @click="openQuickHelp"
                                id="wit-tabbed-form__header-quick-help"
                            >
                                <i class="icon-notebook"></i>
                                <span> Docs</span>
                            </b-btn>
                            <slot name="header-right-btn--after"></slot>
                        </slot>
                    </div>
                </div>
            </slot>
        </div>
        <slot name="under-header"></slot>
        <div role="tablist" class="tabbed-form">
            <b-card no-body class="mb-1 tabbed-form__card" v-for="(step, index) in steps" v-bind:key="step.name">
                <b-card-header
                    @click="toggleStep(index)"
                    :disabled="step.customToggleInvalid || (index > 0 && steps[index - 1].invalid)"
                    header-tag="header"
                    class="p-1 form-step-name"
                    role="tab"
                    :id="`wit-tabbed-form__header-${index}`"
                >
                    <div
                        :class="
                            `wit-tabbed-list__header wit-tabbed-list__header-${
                                step.isCompleted ? 'completed' : 'notcompleted'
                            }`
                        "
                    >
                        <img class="form-step" :src="getStepImg(index)" />
                        <img class="form-step-check" v-if="step.isCompleted" src="@/assets/img/check.png" />
                        <slot :name="`step-${index + 1}-header`" v-bind:step="bindStep(step, index)">
                            {{ step.name }}
                        </slot>
                    </div>
                </b-card-header>
                <b-collapse v-model="tabOpen[index]" role="tabpanel" :ref="`step-${index + 1}-collapse`">
                    <b-card-body>
                        <b-form class="step-form">
                            <slot :name="`step-${index + 1}-form`" v-bind:step="bindStep(step, index)">
                                <template v-if="step.inputs">
                                    <template v-for="input in notAdvancedInputs[index]">
                                        <slot
                                            :name="`step-${index + 1}-input-${input.name}`"
                                            v-bind:step="bindStep(step, index)"
                                        >
                                            <wit-input-group
                                                v-if="
                                                    input.hasOwnProperty('value') &&
                                                        ((input.hasOwnProperty('vIf') && input.vIf) ||
                                                            !input.hasOwnProperty('vIf'))
                                                "
                                                :key="input.name"
                                                v-model="input.value"
                                                :model="input.model"
                                                :type="input.type"
                                                :input-label="input.inputLabel"
                                                :description="input.description"
                                                :label="input.label"
                                                :placeholder="input.placeholder"
                                                :invalid="input.invalid"
                                                :valid="input.valid"
                                                :step="bindStep(step, index)"
                                                :id="input.id ? input.id : `${formId}-${input.name}`"
                                                :options="input.options"
                                                :readonly="input.readonly"
                                                :disabled="input.disabled || disabled"
                                                :max="input.max"
                                                :min="input.min"
                                                :checked="input.checked"
                                                :unchecked="input.unchecked"
                                                :customLabel="input.customLabel"
                                                :notReady="input.notReady"
                                                :notReadyMessage="input.notReadyMessage"
                                                :reduce="input.reduce"
                                                :allowEmpty="input.allowEmpty"
                                                :sub-label="input.subLabel"
                                                :subtract-sub-label="input.subtractSubLabel"
                                                :shopUrl="input.shopUrl"
                                                @update:shopUrl="
                                                    value => emitUpdate(value, input, 'shopUrl', input.onInput)
                                                "
                                                :default-expand-level="input.defaultExpandLevel"
                                                :before-clear-all="input.beforeClearAll"
                                                :multiple="input.multiple"
                                                :value-format="input.valueFormat"
                                                :value-consists-of="input.valueConsistsOf"
                                                :normalizer="input.normalizer"
                                                :preselect-first="input.preselectFirst"
                                                :scope="input.scope"
                                                :requestedScopes="input.requestedScopes"
                                                :visible-hourly="input.visibleHourly"
                                                :visible-daily="input.visibleDaily"
                                                :visible-weekly="input.visibleWeekly"
                                                :visible-monthly="input.visibleMonthly"
                                                :visible-every-x-minutes="input.visibleEveryXMinutes"
                                                @input="value => emitInput(value, input, input.onInput)"
                                            >
                                                <template v-slot:right-col>
                                                    <slot
                                                        :name="`step-${index + 1}-input-${input.name}-right-col`"
                                                        v-bind:step="bindStep(step, index)"
                                                    ></slot>
                                                </template>
                                            </wit-input-group>
                                        </slot>
                                    </template>
                                    <p
                                        v-if="advancedInputs[index].length"
                                        :class="{
                                            'm-1': true,
                                            'collapse-section-button': true,
                                            'arrow-right': showAdvanced[index],
                                            'arrow-down': !showAdvanced[index],
                                        }"
                                        @click="() => toggleAdvanced(index)"
                                    >
                                        Advanced options
                                    </p>
                                    <b-collapse v-model="showAdvanced[index]">
                                        <template v-for="input in advancedInputs[index]">
                                            <slot
                                                :name="`step-${index + 1}-input-${input.name}`"
                                                v-bind:step="bindStep(step, index)"
                                            >
                                                <wit-input-group
                                                    v-if="
                                                        input.hasOwnProperty('value') &&
                                                            ((input.hasOwnProperty('vIf') && input.vIf) ||
                                                                !input.hasOwnProperty('vIf'))
                                                    "
                                                    :key="input.name"
                                                    v-model="input.value"
                                                    :model="input.model"
                                                    :type="input.type"
                                                    :input-label="input.inputLabel"
                                                    :description="input.description"
                                                    :label="input.label"
                                                    :placeholder="input.placeholder"
                                                    :invalid="input.invalid"
                                                    :valid="input.valid"
                                                    :step="bindStep(step, index)"
                                                    :id="input.id ? input.id : `${formId}-${input.name}`"
                                                    :options="input.options"
                                                    :readonly="input.readonly"
                                                    :disabled="input.disabled || disabled"
                                                    :max="input.max"
                                                    :min="input.min"
                                                    :checked="input.checked"
                                                    :unchecked="input.unchecked"
                                                    :customLabel="input.customLabel"
                                                    :notReady="input.notReady"
                                                    :notReadyMessage="input.notReadyMessage"
                                                    :reduce="input.reduce"
                                                    :allowEmpty="input.allowEmpty"
                                                    :sub-label="input.subLabel"
                                                    :subtract-sub-label="input.subtractSubLabel"
                                                    :shopUrl="input.shopUrl"
                                                    @update:shopUrl="
                                                        value => emitUpdate(value, input, 'shopUrl', input.onInput)
                                                    "
                                                    :default-expand-level="input.defaultExpandLevel"
                                                    :before-clear-all="input.beforeClearAll"
                                                    :multiple="input.multiple"
                                                    :value-format="input.valueFormat"
                                                    :value-consists-of="input.valueConsistsOf"
                                                    :normalizer="input.normalizer"
                                                    :scope="input.scope"
                                                    :track-by="input.trackBy"
                                                    :visible-hourly="input.visibleHourly"
                                                    :visible-daily="input.visibleDaily"
                                                    :visible-weekly="input.visibleWeekly"
                                                    :visible-monthly="input.visibleMonthly"
                                                    :visible-every-x-minutes="input.visibleEveryXMinutes"
                                                    @input="value => emitInput(value, input, input.onInput)"
                                                >
                                                    <template v-slot:right-col>
                                                        <slot
                                                            :name="`step-${index + 1}-input-${input.name}-right-col`"
                                                            v-bind:step="bindStep(step, index)"
                                                        ></slot>
                                                    </template>
                                                </wit-input-group>
                                            </slot>
                                        </template>
                                    </b-collapse>
                                </template>
                            </slot>
                            <slot :name="`step-${index + 1}-feedback`" v-bind:step="bindStep(step, index)"></slot>
                            <slot :name="`step-${index + 1}-footer`" v-bind:step="bindStep(step, index)">
                                <b-row>
                                    <b-col>
                                        <Feedback
                                            :state="checkPage[index] ? !step.invalid : null"
                                            invalid="You need to finish this step before accessing the next one"
                                            valid="This step is complete"
                                        ></Feedback>
                                        <b-button
                                            @click="index === steps.length - 1 ? finish() : nextPage(step)"
                                            :disabled="checkPage[index] && step.invalid"
                                            variant="primary"
                                            :id="
                                                index === steps.length - 1
                                                    ? `${formId}-finish`
                                                    : `${formId}-step-${index + 1}-next`
                                            "
                                        >
                                            <i class="icon-check"></i>
                                            <span v-if="index === steps.length - 1 && details"> {{ updateLabel }}</span>
                                            <span v-else-if="index === steps.length - 1"> Finish</span>
                                            <span v-else> Next</span>
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </slot>
                        </b-form>
                    </b-card-body>
                </b-collapse>
            </b-card>
        </div>

        <WitModal
            v-model="showReset"
            title="You have recently started this form"
            variant="warning"
            customClass="reset-form-modal"
            size="md"
        >
            <div class="reset-form-modal__text">Do you wish to continue with your previous data?</div>
            <b-row class="d-block text-center">
                <b-button variant="success" size="md" @click="continueForm">Yes</b-button>
                <span class="pad-buttons" />
                <b-button variant="danger" size="md" @click="resetForm">No</b-button>
            </b-row>
        </WitModal>
    </b-card>
</template>

<script>
import VueNotifications from 'vue-notifications'
import {mapGetters} from 'vuex'

import Feedback from '@/components/Feedback'
import WitModal from '@/components/Modals/WitModal'
import EventBus from '@/eventBus'

export default {
    components: {
        Feedback,
        WitInputGroup: () => import('@/components/Inputs/WitInputGroup.vue'),
        WitModal,
    },
    props: {
        formId: {
            type: String,
            required: true,
        },
        steps: {
            type: Array,
            required: true,
        },
        headerLogo: {
            type: String,
        },
        headerText: {
            type: String,
        },
        noHeader: {
            type: Boolean,
        },
        details: {
            type: Boolean,
        },
        fetched: {
            type: Boolean,
        },
        dirty: {
            type: Boolean,
        },
        update: {
            type: Function,
        },
        updateRedirect: {
            type: String,
        },
        updateLabel: {
            type: String,
            default: 'Update',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        tabIndex: {
            type: Number,
        },
    },
    notifications: {
        updated: {
            type: VueNotifications.types.success,
            title: 'Updated successfully',
        },
        updatedError: {
            type: VueNotifications.types.error,
            title: 'Something went wrong',
        },
    },
    data() {
        return {
            localTabIndex: 0,
            checkPage: this.steps.map(el => false),
            showAdvanced: this.steps.map(el => false),
            showButton: false,
        }
    },
    computed: {
        ...mapGetters({
            showQuickHelp: 'tutorial/showQuickHelp',
        }),
        tabOpen() {
            return this.steps.map((step, index) => (index === this.localTabIndex ? true : false))
        },
        showReset: {
            get() {
                return this.$store.state.form.showReset
            },

            set(value) {
                this.$store.commit('form/SET_SHOWRESET', value)
            },
        },
        anyStepInvalid() {
            return this.steps.some(step => step.invalid)
        },
        notAdvancedInputs() {
            return this.steps.map(step => (step.inputs ? step.inputs.filter(input => !input.isAdvanced) : []))
        },
        advancedInputs() {
            return this.steps.map(step => (step.inputs ? step.inputs.filter(input => input.isAdvanced) : []))
        },
    },
    watch: {
        localTabIndex() {
            const form = document.getElementById(this.formId)
            form.scrollIntoView()

            this.updateIndex()
        },
        tabIndex() {
            this.localTabIndex = this.tabIndex
            this.updateIndex()
        },
    },
    async created() {
        if (this.tabIndex) {
            this.localTabIndex = this.tabIndex
            this.updateIndex()
        }

        EventBus.$on(`changePageFromStep-${this.formId}`, this.handleChangePage)
        setTimeout(() => (this.showButton = true), 2000)
    },
    beforeDestroy() {
        EventBus.$off(`changePageFromStep-${this.formId}`, this.handleChangePage)
    },
    methods: {
        getHeaderLogoImg() {
            return require(`@/assets/${this.headerLogo}`)
        },

        getStepImg(index) {
            return require(`@/assets/gcp-icons/step${index + 1}.png`)
        },

        bindStep(step, index) {
            return {
                ...step,
                checked: this.checkPage[index],
                check: () => {
                    this.checkPage[index] = true
                    this.$forceUpdate()
                },
                nextPage: () => this.nextPage(step),
                isOpen: this.localTabIndex === index,
            }
        },

        async finish() {
            if (this.details) {
                if (!this.disabled) {
                    await this.updateClick({silent: true})
                }

                if (this.updateRedirect) {
                    this.$projectRouter.push(this.updateRedirect)
                }
            } else {
                if (!this.disabled) {
                    this.$emit('finish', this.bindStep(this.steps[this.steps.length - 1], this.steps.length - 1))
                }
            }
        },

        async updateClick(options) {
            try {
                await this.update()

                if (!options || (options && !options.silent)) {
                    this.updated()
                }
            } catch (e) {
                this.updatedError()
                this.$errorHandler.report(e, 'Could not update form')
            }
        },

        async toggleStep(index, silent) {
            this.localTabIndex = index
            this.$forceUpdate()

            if (this.steps[this.localTabIndex].onOpen) {
                await this.steps[this.localTabIndex].onOpen()
            }

            this.$emit('changeTab', this.localTabIndex)
            if (!silent) {
                setTimeout(() => EventBus.$emit('changePageFromForm', this.localTabIndex), 300)
            }
        },

        async nextPage(step, silent) {
            this.checkPage[this.localTabIndex] = true
            this.$forceUpdate()

            if (!step.invalid) {
                this.localTabIndex++
                if (this.steps[this.localTabIndex].onOpen) {
                    await this.steps[this.localTabIndex].onOpen()
                }

                this.$emit('changeTab', this.localTabIndex)
                if (!silent) {
                    setTimeout(() => EventBus.$emit('changePageFromForm', this.localTabIndex), 300)
                }
                return true
            } else return false
        },

        handleChangePage(page, resolve, reject) {
            if (page > this.localTabIndex) {
                const pagesToChange = [...Array(page - this.localTabIndex).keys()].map(p => ({
                    invalid: this.steps[page - p - 1].invalid,
                    index: page - p,
                }))

                const nextPage = pagesToChange.find(el => !el.invalid)

                if (nextPage) {
                    const pageChanged = this.toggleStep(nextPage.index, true)
                    resolve(nextPage.index)
                } else {
                    reject(new Error('could not change page'))
                }
            } else {
                this.toggleStep(page, true)
                resolve()
            }
        },

        continueForm() {
            this.$emit('continue')
            this.showReset = false
        },

        resetForm() {
            this.$emit('reset')
            this.showReset = false
        },

        emitInput(value, input, onInput) {
            this.$emit('input', value, input, onInput)
        },

        emitUpdate(value, input, field, onInput) {
            this.$emit('update', value, input, field, onInput)
        },

        toggleAdvanced(index) {
            this.$set(this.showAdvanced, index, !this.showAdvanced[index])
        },

        updateIndex() {
            this.$emit('update:tabIndex', this.localTabIndex)
        },

        setPage(index) {
            this.localTabIndex = index
        },
        openQuickHelp() {
            EventBus.$emit('openQuickHelp')
        },
    },
}
</script>

<style lang="scss">
@import '@/assets/scss/_transitions';

.pad-buttons {
    padding-left: 2rem;
}

.reset-form-modal {
    header {
        h5 {
            font-size: 0.9rem;
        }
    }

    .modal-body {
        .reset-form-modal__text {
            margin-bottom: 15px;
        }
    }
}

.wit-tabbed-list__header-completed {
    text-decoration: line-through;
}

.wit-tabbed-form__header {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;
    min-height: 33px;
}

#wit-tabbed-form__header-update {
    box-shadow: 0 1px 2px rgba(255, 193, 7, 0.15);
    position: relative;
    animation: jump 1s linear infinite;
}

.tabbed-form {
    .tabbed-form__card {
        border: 0;
        box-shadow: none;
        background: none;
    }
}
</style>
